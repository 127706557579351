var state = {
    items: [],
};
// getters
var getters = {
    items: function (state) {
        return state.items;
    },
};
// actions
var actions = {};
// mutations
var mutations = {
    addItem: function (state, item) {
        // add item to items
        state.items.push(item);
    },
    // update itemNumber in items
    updateItemNumber: function (state, payload) {
        state.items[payload.index].itemNumber = payload.itemNumber;
        state.items[payload.index].customModelId = payload.customModelId;
        state.items[payload.index].image = payload.image;
        state.items[payload.index].ringsize = payload.ringsize;
    },
    // update ringsize in items
    updateRingsize: function (state, payload) {
        state.items[payload.index].ringsize = payload.ringsize;
    },
    // update engraving in items
    updateEngraving: function (state, payload) {
        state.items[payload.index].engraving = payload.engraving;
    },
    // update engravingFont in items
    updateEngravingFont: function (state, payload) {
        state.items[payload.index].engravingFont = payload.engravingFont;
    },
    // update active in items
    updateActive: function (state, payload) {
        state.items[payload.index].active = payload.active;
    },
    // remove item from items
    removeItem: function (state, index) {
        state.items.splice(index, 1);
    },
    // clear items
    clearItems: function (state) {
        state.items = [];
    },
};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    actions: actions,
    mutations: mutations,
};
