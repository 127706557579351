var state = {
    currentView: "gallery",
};
// getters
var getters = {
    currentView: function (state) {
        return state.currentView;
    },
};
// actions
var actions = {};
// mutations
var mutations = {
    setCurrentView: function (state, currentView) {
        state.currentView = currentView;
    },
};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    actions: actions,
    mutations: mutations,
};
