var state = {
    galleryId: 0,
};
// getters
var getters = {
    galleryId: function (state) {
        return state.galleryId;
    },
};
// actions
var actions = {};
// mutations
var mutations = {
    setGalleryId: function (state, galleryId) {
        state.galleryId = galleryId;
    },
};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    actions: actions,
    mutations: mutations,
};
