import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
// import router functions
import { createRouter, createWebHistory } from "vue-router";
// setup routes
var router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: "/",
            name: "Home",
            component: function () { return import("./views/Home.vue"); },
            meta: {
                title: "Brinckmann & Lange - Trauring Kollektion",
                description: "Brinckmann & Lange - Trauring Kollektion",
            },
        },
        {
            path: "/admin/",
            name: "Admin",
            component: function () { return import("./views/admin/Admin.vue"); },
            meta: {
                title: "Brinckmann & Lange - Trauring Kollektion - Admin",
                description: "Brinckmann & Lange - Trauring Kollektion - Admin",
            },
        },
        {
            path: "/admin/login/",
            name: "AdminLogin",
            component: function () { return import("./views/admin/Login.vue"); },
            meta: {
                title: "Brinckmann & Lange - Trauring Kollektion - Admin Login",
                description: "Brinckmann & Lange - Trauring Kollektion - Admin Login",
            },
        },
        {
            path: "/bml/gallery",
            name: "GalleryBml",
            component: function () { return import("./views/bml/Gallery.vue"); },
            meta: {
                title: "Brinckmann & Lange - Trauring Kollektion - Galerie",
                description: "Brinckmann & Lange - Trauring Kollektion - Galerie",
            },
        },
        {
            path: "/christ/gallery",
            name: "GalleryChrist",
            component: function () { return import("./views/christ/Gallery.vue"); },
            meta: {
                title: "Christ - Trauring Kollektion - Galerie",
                description: "Christ - Trauring Kollektion - Galerie",
            },
        },
        {
            path: "/bml/article/:articleId1/:articleId2?/:articleId3?",
            name: "ArticleBml",
            component: function () { return import("./views/bml/Article.vue"); },
            meta: {
                title: "Brinckmann & Lange - Trauring Kollektion - Artikel",
                description: "Brinckmann & Lange - Trauring Kollektion - Artikel",
            },
        },
        {
            path: "/christ/article/:articleId1/:articleId2?/:articleId3?",
            name: "ArticleChrist",
            component: function () { return import("./views/christ/Article.vue"); },
            meta: {
                title: "Christ - Trauring Kollektion - Artikel",
                description: "Christ - Trauring Kollektion - Artikel",
            },
        },
        {
            path: "/bml/shoppingcart",
            name: "ShoppingcartBml",
            component: function () { return import("./views/bml/Shoppingcart.vue"); },
            meta: {
                title: "Brinckmann & Lange - Trauring Kollektion - Warenkorb",
                description: "Brinckmann & Lange - Trauring Kollektion - Warenkorb",
            },
        },
        {
            path: "/christ/shoppingcart",
            name: "ShoppingcartChrist",
            component: function () { return import("./views/christ/Shoppingcart.vue"); },
            meta: {
                title: "Christ - Trauring Kollektion - Warenkorb",
                description: "Christ - Trauring Kollektion - Warenkorb",
            },
        },
        {
            path: "/bml/order",
            name: "OrderBml",
            component: function () { return import("./views/bml/Order.vue"); },
            meta: {
                title: "Brinckmann & Lange - Trauring Kollektion - Bestellung",
                description: "Brinckmann & Lange - Trauring Kollektion - Bestellung",
            },
        },
        {
            path: "/christ/order",
            name: "OrderChrist",
            component: function () { return import("./views/christ/Order.vue"); },
            meta: {
                title: "Christ - Trauring Kollektion - Bestellung",
                description: "Christ - Trauring Kollektion - Bestellung",
            },
        },
        {
            path: "/bml/login",
            name: "LoginBml",
            component: function () { return import("./views/bml/Login.vue"); },
            meta: {
                title: "Brinckmann & Lange - Trauring Kollektion - Login",
                description: "Brinckmann & Lange - Trauring Kollektion - Login",
            },
        },
        {
            path: "/christ/login",
            name: "LoginBml",
            component: function () { return import("./views/christ/Login.vue"); },
            meta: {
                title: "Christ - Trauring Kollektion - Login",
                description: "Christ - Trauring Kollektion - Login",
            },
        },
    ],
});
router.beforeEach(function (to, from) {
    document.title = to.meta.title;
    // set meta description tag
    var description = document.querySelector('meta[name="description"]');
    if (description) {
        description.setAttribute("content", to.meta.description);
    }
    if (typeof from === "undefined") {
        console.warn("router from is undefined");
    }
    if (typeof to === "undefined") {
        console.warn("router to is undefined");
    }
});
import "./assets/css/index.css";
import "./assets/css/fonts.css";
createApp(App).use(router).use(store).mount("#cbl");
