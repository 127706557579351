var state = {
    orderId: "",
    orderData: {},
    isOrderSuccess: false,
};
// getters
var getters = {
    getOrderId: function (state) {
        return state.orderId;
    },
    isOrderSuccess: function (state) {
        return state.isOrderSuccess;
    },
};
// actions
var actions = {};
// mutations
var mutations = {
    setOrderId: function (state, id) {
        state.orderId = id;
    },
    setOrderSuccess: function (state, status) {
        state.isOrderSuccess = status;
    },
    resetOrder: function (state) {
        state.isOrderSuccess = false;
        state.orderData = {};
    },
};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    actions: actions,
    mutations: mutations,
};
