var savedItems = localStorage.getItem("cbl:shoppingcart");
var items = savedItems ? JSON.parse(savedItems) : [];
var state = {
    items: items,
};
// getters
var getters = {
    items: function (state) {
        return state.items;
    },
};
// actions
var actions = {};
// mutations
var mutations = {
    addItem: function (state, item) {
        // add item to items
        state.items.push(item);
        // update LocalStorage
        localStorage.setItem("cbl:shoppingcart", JSON.stringify(state.items));
    },
    removeItem: function (state, index) {
        // remove item from items
        state.items.splice(index, 1);
        // update LocalStorage
        localStorage.setItem("cbl:shoppingcart", JSON.stringify(state.items));
    },
    clearItems: function (state) {
        // clear items
        state.items = [];
        // update LocalStorage
        localStorage.setItem("cbl:shoppingcart", JSON.stringify(state.items));
    },
};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    actions: actions,
    mutations: mutations,
};
