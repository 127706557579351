import { useCookies } from "vue3-cookies";
var cookies = useCookies().cookies;
var state = {
    token: "",
};
// getters
var getters = {
    token: function (state) {
        return state.token;
    },
};
// actions
var actions = {
    // remove token from store and delete cookie
    logout: function (_a) {
        var commit = _a.commit;
        commit("setToken", "");
        cookies.remove("cbltoken");
    },
};
// mutations
var mutations = {
    setToken: function (state, token) {
        state.token = token;
    },
};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    actions: actions,
    mutations: mutations,
};
