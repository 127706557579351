import { createStore } from "vuex";
import main from "./main";
import gallery from "./gallery";
import article from "./article";
import shoppingcart from "./shoppingcart";
import login from "./login";
import order from "./order";
var store = createStore({
    modules: {
        main: main,
        gallery: gallery,
        article: article,
        shoppingcart: shoppingcart,
        login: login,
        order: order,
    },
});
export function useStore() {
    return store;
}
export default store;
